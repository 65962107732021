import Vue from 'vue'
import {Button, Carousel, CarouselItem, Container, Form, FormItem, Input, Select,Option,Dialog} from 'element-ui'

Vue.use(Button)
Vue.use(Container)
Vue.use(Form)
Vue.use(Input)
Vue.use(FormItem)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Select)
Vue.use(Option)
Vue.use(Dialog)
