import fetch from '@/utils/request'

export function listTrainResult(data) {
  return fetch({
    url: '/api/trainResult/list', method: 'get', data
  })
}

export function trainResultDetail(data) {
  return fetch({
    url: '/api/trainResult/detail', method: 'get', data
  })
}

export function saveTrainResult(data) {
  return fetch({
    url: '/api/trainResult', method: 'post', data
  })
}

export function removeTrainResult(data) {
  return fetch({
    url: '/api/trainResult', method: 'delete', data
  })
}

export function updateTrainResult(data) {
  return fetch({
    url: '/api/trainResult', method: 'put', data
  })
}
