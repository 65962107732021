/* eslint-disable */
import axios from 'axios'
import {getToken} from '@/utils/auth'
import {Message} from "element-ui";
import Cookies from 'js-cookie';

const _Message = Message
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 10000
})
const TokenKey = 'Authorization'
// 請求攔截器
service.interceptors.request.use(
    config => {
        let current = config
        config.headers.platform = 'front'
        const isToken = current.headers?.isToken === false;
        if (getToken() && !isToken) {
            config.headers.Authorization = `Bearer ${getToken()}`;
        }
        if (config.method === 'get') {
            config.params = current.data;
        } else if (['put', 'delete', 'post'].includes(config.method.toUpperCase())) {
            config.data = current.data;
        }
        return config;
    },
    error => Promise.reject(error)
);

// 響應攔截器
service.interceptors.response.use(
    response => {

        const data = response.data
        const code = data.code
        const msg = data.msg
        if (code === 500 || code === 401) {
            _Message({
                message: msg,
                type: "error"
            })
            if (code === 401) {
                Cookies.remove(TokenKey)
                location.href = '/'
            }
        }
        return Promise.resolve(response.data)
    },
    // 接口響應有誤捕捉
    error => {
        let {message} = error
        if (message === 'Network Error') {
            message = '系統接口連接異常'
        } else if (message.includes('timeout')) {
            message = '系統接口請求超時'
        } else if (message.includes('Request failed with status code')) {
            message = '系統接口' + message.substr(message.length - 3) + '異常'
        }
        _Message.error(message)
        return Promise.reject(error)
    }
)

export default service
