<template>
  <div class="top-base">
    <div class="app-logo">
      <img src="../../../assets/eye-track/logo.png" alt="">
      <div>
        <p class="app-description">眼神追蹤測試系統</p>
        <p class="app-description">Eye-tracking Test System</p></div>
    </div>
    <el-button type="primary" size="medium" class="exit-button" @click="handleSignOut">
      退出<br>Exit
    </el-button>
  </div>
</template>

<script>

import {removeToken} from "@/utils/auth";

export default {
  methods: {
    handleSignOut() {
      // 後台不記錄Auth狀態
      removeToken()
      this.$router.push("/sign-in")
      window.location.reload()
    }
  }
}
</script>
<style scoped lang="scss">
.top-base {
  display: flex;
  position: absolute;
  width: 100%;
  height: 6.3%;
  background: #6E4E9E;
  border-radius: 0 0 0 0;
  opacity: 1;

  .exit-button {
    position: absolute;
    display: flex;
    top: 50%;
    transform: translate(0, -50%);
    left: calc(97% - 38px);
    justify-content: center;
    align-items: center;
    line-height: 1.2;
    height: 50px;
    width: 80px;
    color: #6E4E9E;
    background: #FFFFFF;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.16);
    border-radius: 14px;
    opacity: 1;
    border-color: transparent;
  }

  .app-logo {
    display: flex;
    flex-direction: row;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 2.1%;
    transform: translate(0, -50%);

    & img {
      width: 40px;
      height: 40px;
      margin-right: 14px;
    }

    .app-description {
      display: flex;
      color: #FFFFFF;
      height: 20px;
      font-size: 15px;
      font-family: Segoe UI-Bold, Segoe UI, serif;
      font-weight: bold;
      letter-spacing: 6px;
      align-items: center;
      width: 100%;
      background-color: transparent;
      white-space: nowrap;
      margin: 3px;
    }
  }

}
</style>
