<template>
  <el-container>
    <div class="calibrate-container-base" ref="calibrate_container_base_ref">
      <topBase v-show="current_required_env_state.show_app_index||handleDivState(1)"/>
      <!--流媒体,canvas-->
      <div class="calibrate-media-base"
           v-show="current_required_env_state.show_app_index||face_land_mark.calibrate_before.show_media">
        <!--攝像頭畫面-->
        <div class="calibrate-video" :style="{width:container_w_h[0],height:container_w_h[1]}">
          <video ref="calibrate_video_ref" :style="{width:container_w_h[0],height:container_w_h[1]}"/>
          <!--canvas-->
          <div class="calibrate-canvas" :style="{width:container_w_h[0],height:container_w_h[1]}">
            <canvas ref="calibrate_canvas_ref" :style="{width:container_w_h[0],height:container_w_h[1]}"/>
          </div>
        </div>
      </div>
      <div v-show="current_required_env_state.show_app_index">
        <!--校準按鈕及注意事項-->
        <div class="start-calibrate-base">
          <el-button type="primary" size="medium"
                     :loading="!current_required_env_state.init_app_env_completed"
                     :disabled="!current_required_env_state.init_app_env_completed"
                     class="start-calibrate-button"
                     @click="handleStartCalibrate">
            開始<br>Start
          </el-button>
        </div>
        <!--注意事項-->
        <div class="calibrate-notice-first">
          <p>• 請將您的頭部按照提⽰放於屏幕中央；</p>
          <p>• Please move your head to the center of the screen as instructed;</p>
          <p>• 保持頭部位置，點擊開始按鈕進入測試。</p>
          <p>• Keep your head in position and click the “Start” button to start testing.</p>
        </div>
      </div>
      <!--校準target-->
      <div v-show="handleDivState(0)">
        <!--target圖像-->
        <div
            ref="calibrate_target_image_ref"
            class="calibrate-target-image"
            :style="{ top: face_land_mark.calibrate_before.top, left: face_land_mark.calibrate_before.left }">
          <img src="../../../assets/eye-track/target.png" alt="">
        </div>
        <!--倒計時-->
        <div class="current-calibrate-countdown-notice">
          {{ face_land_mark.calibrate_before.current_second }}
        </div>
      </div>
      <!--用戶臉部狀態提示-->
      <div class="user-face-state-notice" v-show="face_land_mark.calibrate_before.show_media"
           :style="{ color:face_land_mark.check_state_notice.color }">
        <div v-for="(item,index) in face_land_mark.check_state_notice.message.split('|')" :key="index">
          {{ item }}
        </div>
      </div>
      <!--眼球追蹤target-->
      <div v-show="handleDivState(1)||handleDivState(5)||handleDivState(6)">
        <div class="eye-track-image" ref="eye_track_image_ref"
             :style="{ position: 'absolute', transition: '50ms', left:face_land_mark.calibrate_after.target_div.left+'%',top:face_land_mark.calibrate_after.target_div.top +'%'}">
          <img src="../../../assets/eye-track/target.png" alt="">
        </div>
      </div>
      <!--用戶校準圓環-->
      <div class="calibrate-ring-image" v-show="handleDivState(6)" ref="calibrate_ring_image_ref"
           :style="{ position: 'absolute', transition: '50ms', left:face_land_mark.calibrate_after.ring_div.left+'%',top:face_land_mark.calibrate_after.ring_div.top+'%' }">
        <img src="../../../assets/eye-track/ring.png" alt="">
      </div>
      <!--校準信息提示-->
      <div class="calibrate-ring-image-notice" v-show="handleDivState(6)"
           :style="{ color:'#67449A' }">
        <div v-for="(item,index) in face_land_mark.calibrate_after.ring_div.notice.split('|')" :key="index">
          {{ item }}
        </div>
      </div>
      <!--模板選擇div塊 // 此處使用v-if-->
      <div class="select-train-template" v-if="handleDivState(1)">
        <p class="select-train-template-notice">請點擊模板圖片/視頻選擇一個模板</p>
        <p class="select-train-template-notice en">Please click to select an image/video template</p>
        <el-carousel class="el-carousel" :autoplay="false" arrow="always" height="600px">
          <el-carousel-item class="el-carousel-item"
                            v-for="(trainTemplateGroup, index) in chunkTrainTemplates(train_templates, 3)"
                            :key="index">
            <div class="carousel-item-image" :class="{ 'two-items': train_templates.length === 2 }"
                 v-for="template in trainTemplateGroup" :key="template.trainTemplateId">
              <!--視頻格式-->
              <div v-if="template.trainTemplateType === 1">
                <video :src="handleTrainTemplateFilePath(template.filePath)" autoplay="autoplay" loop
                       :style="{borderColor: template.trainTemplateId === train_obj.train_template_id ? '#67449A' : ''}"
                       @click="handleSelectTrainTemplate(template)"
                >
                  Your browser does not support the video tag.
                </video>
              </div>
              <!--圖片格式-->
              <div v-else-if="template.trainTemplateType ===0">
                <img :src="handleTrainTemplateFilePath(template.filePath)" alt=""
                     :style="{borderColor: template.trainTemplateId === train_obj.train_template_id ? '#67449A' : ''}"
                     @click="handleSelectTrainTemplate(template)"/>
              </div>
              <p class="carousel-item-name">{{ template.trainTemplateName }}</p>
            </div>
          </el-carousel-item>
        </el-carousel>
        <!--  模板,持續時間選擇-->
        <div class="train-template-duration-and-start">
          <div class="train-template-duration-and-start-notice-select">
            <span class="train-template-duration-and-start-notice-select-label">請選擇測試時長<br> Please select the test duration：</span>
            <el-select class="duration_select_option" v-model="train_obj.duration" placeholder="請選擇持續時間">
              <el-option
                  v-for="select_option in durationOptions"
                  :key="select_option.value"
                  :label="select_option.label?select_option.label:train_obj.duration+'s'"
                  :value="select_option.value">
              </el-option>
            </el-select>
          </div>
          <div class="train-template-duration-start-calibrate">
            <!--選擇圖片後可點擊-->
            <el-button class="start-train-button" title="請選擇模板後再開始"
                       :disabled="train_obj.train_template_id===''"
                       @click="handleStartEyeTrack()">開始<br>Start
            </el-button>
            <el-button plain class="re-calibrate-button" title="重新校準" @click="handleReCalibrate()">重新校準<br>ReCalibrate
            </el-button>
          </div>
        </div>
      </div>
      <!--眼神追蹤div塊-->
      <div class="eye-track" v-show="handleDivState(2)">
        <img ref="eye_track_resource" crossorigin="anonymous" v-if="train_obj.train_template_type === 0"
             :src="train_obj.file_path"
             width="100%" height="100%" alt=""
             :key="train_obj.file_path"/>
        <video ref="eye_track_resource" crossorigin="anonymous" autoplay="autoplay" loop
               v-if="train_obj.train_template_type === 1"
               :src="train_obj.file_path"
               width="100%"
               height="100%"
               style=" background-color: #818181;">
          Your browser does not support the video tag.
        </video>
      </div>
      <!--模型生成提示頁面-->
      <div v-show="handleDivState(3)">
        <div class="calibrate-notice-load">
          校準完成，測試遊戲即將開始......
        </div>
        <div class="calibrate-notice-load en">
          Calibration completed, the test program is about to begin ……
        </div>
      </div>
      <!--這個div使用v-show|if會導致heatMap初始化canvas沒有尺寸-->
      <div ref="heatmapElement" class="heat-map-env" :style="{zIndex:heat_map.z_index}">
        <el-button class="back-to-index-button" :style="{zIndex:heat_map.z_index+1}" v-show="handleDivState(4)&&
              current_required_env_state.capture_screen_completed"
                   @click="handleBackToTrainPage()">返回測試頁<br>Return to the test page
        </el-button>
      </div>
      <!--校準提示音一-->
      <div class="audio1">
        <audio ref="audioRef1" src="../../../assets/eye-track/audio/calibrate1.mp3"></audio>
      </div>
      <!--校准提示音二-->
      <div class="audio2">
        <audio ref="audioRef2" src="../../../assets/eye-track/audio/calibrate2.mp3"></audio>
      </div>
      <!--校准文字提示一-->
      <div class="calibrate-notice-first-dialog">
        <el-dialog
            :visible.sync="face_land_mark.calibrate_before.firstCalibrateNoticeDialogVisible"
            width="50.5%"
            :before-close="handleCloseCalibrateNoticeDialog">
          <template #title>
            <p class="calibrate-notice-dialog-title">校準步驟⼀：頭部保持靜⽌不動，眼球盯緊移動靶⼼</p>
            <p class="calibrate-notice-dialog-title">Calibration Step 1: Keep your head still and your eyes focused on
              the moving bullseye</p>
          </template>
          <p class="calibrate-notice-dialog-normal">說明：</p>
          <p class="calibrate-notice-dialog-normal">Instructions:</p>
          <p class="calibrate-notice-dialog-normal">
            在此校準步驟中，屏幕中將出現⼀個移動靶⼼，請您做到以下兩點：</p>
          <p class="calibrate-notice-dialog-normal">
            During this calibration step, a moving bullseye will appear on the screen. Please do the following:</p>
          <p class="calibrate-notice-dialog-padding">1.
            眼球始終盯緊靶⼼，跟隨靶⼼轉移視線，直⾄靶⼼停⽌移動完成校準；</p>
          <p class="calibrate-notice-dialog-padding">&nbsp;&nbsp;&nbsp; To complete calibration, keep gazing at the
            moving bullseye until it stops;</p>
          <p class="calibrate-notice-dialog-padding">2. 全程<span
              style="font-weight: bold;color: #5519af;">保持頭部位置靜⽌不動</span>。</p>
          <p class="calibrate-notice-dialog-padding">&nbsp;&nbsp;&nbsp; <span
              style="font-weight: bold;color: #5519af;">Keep your head still throughout the process.</span></p>
          <p class="calibrate-notice-dialog-padding">請在預備好後，按下 “確定”
            按鈕開始第⼀階段校準。</p>
          <p class="calibrate-notice-dialog-padding">Please press the “OK” button to start the first calibration step
            when you are ready.</p>
          <span slot="footer" class="dialog-footer">
            <el-button class="calibrate-notice-dialog-button" type="primary"
                       @click="handleCloseCalibrateNoticeDialog">確定<br>OK</el-button>
          </span>
        </el-dialog>
      </div>
      <!--校准文字提示二-->
      <div class="calibrate-notice-second-dialog">
        <el-dialog
            :visible.sync="face_land_mark.calibrate_before.secondCalibrateNoticeDialogVisible"
            width="50%"
            :before-close="handleCloseCalibrateNoticeDialog">
          <template #title>
            <p class="calibrate-notice-dialog-title">校準步驟⼆：頭部可⾃然轉動，注意⼒緊跟移動靶⼼</p>
            <p class="calibrate-notice-dialog-title">Calibration Step 2: Turn your head naturally and focus on the
              moving bullseye. </p>
          </template>
          <p class="calibrate-notice-dialog-normal">說明：</p>
          <p class="calibrate-notice-dialog-normal">Instructions:</p>
          <p class="calibrate-notice-dialog-padding">
            在此校準步驟中，屏幕將和之前⼀樣出現⼀個移動靶⼼，請您做到以下兩點：</p>
          <p class="calibrate-notice-dialog-padding">
            During this calibration step, a moving bullseye will appear on the screen as previously. Please do the
            following:</p>
          <p class="calibrate-notice-dialog-padding">1.
            眼球始終盯緊靶⼼，跟隨靶⼼轉移視線，直⾄靶⼼停⽌移動完成校準；</p>
          <p class="calibrate-notice-dialog-padding">&nbsp;&nbsp;&nbsp; To complete calibration, keep gazing at the
            moving bullseye until it stops;</p>
          <p class="calibrate-notice-dialog-padding">2.
            過程中，<span style="font-weight: bold;color: #5519af;">頭部允許放鬆⾃然轉動</span>，保持與⽇常習慣相同即可。</p>
          <p class="calibrate-notice-dialog-padding">&nbsp;&nbsp;&nbsp; During the process,<span
              style="font-weight: bold;color: #5519af;">you can turn your head relaxingly</span> and naturally as usual.
          </p>
          <p class="calibrate-notice-dialog-padding">請在預備好後，按下 “確定”
            按鈕開始第⼆階段校準。</p>
          <p class="calibrate-notice-dialog-padding">Please press the “OK” button to start the second calibration step
            when you are ready.</p>
          <span slot="footer" class="dialog-footer">
            <el-button class="calibrate-notice-dialog-button" type="primary" @click="handleCloseCalibrateNoticeDialog">確定<br>OK</el-button>
          </span>
        </el-dialog>
      </div>
      <!--应用状态提示-->
      <div class="calibrate-system-status-dialog">
        <el-dialog
            :visible.sync="face_land_mark.calibrate_before.systemFullScreenStatusDialogVisible"
            width="50%">
          <template #title>
            <p class="calibrate-notice-dialog-title">應用狀態檢測</p>
            <p class="calibrate-notice-dialog-title">Application Status Detection</p>
          </template>
          <p class="calibrate-notice-dialog-normal">檢測到應用未按預定模式運行，請點擊確定後以全屏模式運行。</p>
          <p class="calibrate-notice-dialog-normal">The application is currently not running in the pre-set mode. Please
            click OK to run it in full-screen mode.</p>
          <span slot="footer" class="dialog-footer">
            <el-button class="calibrate-notice-dialog-button" type="primary"
                       @click="handleFullscreen">確定<br>OK</el-button>
          </span>
        </el-dialog>
      </div>
      <!--重新校准确认提示-->
      <div class="re-calibrate-confirm-dialog">
        <el-dialog
            :visible.sync="face_land_mark.calibrate_before.reCalibrateConfirmDialogVisible" width="50%">
          <template #title>
            <p class="calibrate-notice-dialog-title">重新校準提示</p>
            <p class="calibrate-notice-dialog-title">Confirmation of Recalibration</p>
          </template>
          <p class="calibrate-notice-dialog-normal">確認重新校準嗎？</p>
          <p class="calibrate-notice-dialog-normal">If you confirm to recalibrate, please click “OK”. If not, please
            click “Cancel”.</p>
          <span slot="footer" class="dialog-footer">
              <el-button class="calibrate-notice-dialog-button recalibrate" type="primary"
                         @click="handleReCalibrateConfirm('cancel')">取消<br>Cancel</el-button>
            <el-button class="calibrate-notice-dialog-button recalibrate recalibrate-confirm" plain
                       @click="handleReCalibrateConfirm('confirm')">確定<br>OK</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </el-container>

</template>
<script>

import html2canvas from 'html2canvas';
import screenFull from "screenfull";
import {Message} from "element-ui";
import topBase from "@/views/eye-track/base/topbase.vue"
import {saveTrainResult, updateTrainResult} from "@/api/TrainResult";
import {listTrainTemplate} from "@/api/trainTemplate";
import * as tensorflow from '@tensorflow/tfjs'
import * as heatmap from 'heatmap.js';
import * as faceLandmarksDetection from "@tensorflow-models/face-landmarks-detection";
import {settingDetail} from "@/api/setting";
import {uploadFile} from "@/api/file";
import {sleep} from "@/utils/Time"


export default {
  components: {topBase},
  data() {
    return {
      settings: {
        interpolate_value: 10, // 線性插值數量（值越大，收集的樣本數越多，校準時間越長，默認值為10）
        wait_time: 50,// 線性插值點位移動等待時間，單位 ms（值越大，校準時間越長,默認值為50ms）
        minimum_face_camera_distance_percent: 0.3,// 臉部距攝像機最小距離百分比（默認值0.3）
        maximum_face_camera_distance_percent: 0.5,// 臉部距攝像機最大距離百分比（默認值0.5）
        left_face_camera_distance_percent: 0.6, // 臉部距攝像頭畫面左側最大距離百分比（默認值0.6）
        right_face_camera_distance_percent: 0.4, // 臉部距攝像頭畫面右側側最大距離百分比（默認值0.4）
        top_face_camera_distance_percent: 0.65, // 臉部距攝像頭畫面頂部最大距離百分比（默認值0.65）
        bottom_face_camera_distance_percent: 0.4, // 臉部距攝像頭畫面底部最大距離百分比（默認值0.4）
      },
      heat_map: {
        heatmap_instance: null,
        z_index: -999,
        predict_points: []
      },
      train_obj: {
        show_heat_map: 0,
        heatmap_file_path: '',
        train_result_id: '',
        train_template_type: '',
        train_template_id: '',
        file_path: '',//圖片地址
        duration: 45, //默認持續時間
        result_data: [],//結果集
        duration_select_option: [
          {value: 15, label: '15s'},
          {value: 30, label: '30s'},
          {value: 45, label: '45s'},
          {value: 60, label: '60s'},
          {value: 120, label: '120s'},
        ]
      },
      train_templates: [],
      face_land_mark_detect: null,
      face_land_mark: {
        face_mesh: null,
        eye_key_points: {	// face mesh中固定點位
          leftEye: [249, 263, 362, 373, 374, 380, 381, 382, 384, 385, 386, 387, 388, 390, 398, 466],
          rightEye: [7, 33, 133, 144, 145, 153, 154, 155, 157, 158, 159, 160, 161, 163, 173, 246],
          leftEyebrow: [276, 282, 283, 293, 295, 296, 300, 334, 336],
          rightEyebrow: [46, 52, 53, 63, 65, 66, 70, 105, 107],
          leftIris: [474, 475, 476, 477],
          rightIris: [469, 470, 471, 472]
        },
        media_config: {  // 流媒體參數
          width: 0,
          height: 0
        },
        check_state_notice: { //校準臉部狀態提示
          color: 'red',
          message: ''
        },
        calibrate_after: { // 校準後眼球target
          target_div: {
            top: 50,
            left: 50
          },
          ring_div: {
            top: 50,
            left: 50,
            notice: ''
          }
        },
        calibrate_before: { // 設置主容器寬高
          current_second: null,
          firstCalibrateNoticeDialogVisible: false,
          secondCalibrateNoticeDialogVisible: false,
          systemFullScreenStatusDialogVisible: false,
          reCalibrateConfirmDialogVisible: false,
          show_media: false,
          top: '50%',
          left: '50%',
          position_percent: [
            [50, 50], [40, 40], [30, 30], [20, 20], [3, 3], [20, 3], [30, 3], [40, 3], [50, 3], [60, 3], [70, 3], [80, 3], [97, 3], [80, 20], [70, 30], [60, 40], [50, 50], [40, 60], [30, 70], [20, 80], [3, 97], [20, 97], [30, 97], [40, 97], [50, 97], [60, 97], [70, 97], [80, 97], [97, 97], [80, 80], [70, 70], [60, 60], [50, 50], [50, 50], [40, 40], [30, 30], [20, 20], [3, 3], [20, 3], [30, 3], [40, 3], [50, 3], [60, 3], [70, 3], [80, 3], [97, 3], [80, 20], [70, 30], [60, 40], [50, 50], [40, 60], [30, 70], [20, 80], [3, 97], [20, 97], [30, 97], [40, 97], [50, 97], [60, 97], [70, 97], [80, 97], [97, 97], [80, 80], [70, 70], [60, 60], [50, 50]
          ],
          random_position_percent: [
            [50, 50], [40, 40], [30, 30], [20, 20], [10, 10], [20, 10], [30, 10], [40, 10], [50, 10], [60, 10], [70, 10], [80, 10], [85, 10], [80, 20], [70, 30], [60, 40], [50, 50], [40, 60], [30, 70], [20, 80], [10, 85], [20, 85], [30, 85], [40, 85], [50, 85], [60, 85], [70, 85], [80, 85], [85, 85], [80, 80], [70, 70], [60, 60], [50, 50], [50, 50], [40, 40], [30, 30], [20, 20], [10, 10], [20, 10], [30, 10], [40, 10], [50, 10], [60, 10], [70, 10], [80, 10], [85, 10], [80, 20], [70, 30], [60, 40], [50, 50], [40, 60], [30, 70], [20, 80], [10, 85], [20, 85], [30, 85], [40, 85], [50, 85], [60, 85], [70, 85], [80, 85], [85, 85], [80, 80], [70, 70], [60, 60], [50, 50]
          ]
        },
        train_data_set: { // 訓練模型數據集
          train: {
            n: 0,
            x: null,
            y: null
          },
          val: {
            n: 0,
            x: null,
            y: null
          }
        },
        training: {	// 訓練模型參數
          current_model: null,
          epochs_trained: 0,
          best_module_path: 'localstorage://best-model'
        }
      },
      // 環境狀態
      current_required_env_state: {
        init_app_env_completed: false,
        show_app_index: true,
        create_detector_completed: false,
        estimate_faces_completed: false,
        open_media_completed: false,
        load_model_competed: false,
        in_training: false,
        in_calibrate: false,
        in_calibrate_pause: false,
        in_eye_tracking: false,
        eye_tracking_completed: false,
        in_move_target_to_ring: false,
        capture_screen_completed: false,
        interval: {
          movePredictTargetTimer: null,
          pushResultDataTimer: null,
          moveTargetToRingTimer: null
        },
        iz_move_target_to_ring: false
      },
      base_container_env: {
        width: 0,
        height: 0,
      }
    }
  },
  computed: {
    container_w_h() {
      const this_ = this
      return [`${this_.face_land_mark.media_config.width}px`, `${this_.face_land_mark.media_config.height}px`]
    },
    durationOptions() { // 動態計算選擇框屬性
      const this_ = this
      const selectedOption = this_.train_obj.duration_select_option.find(option => option.value === this_.train_obj.duration);
      if (!selectedOption && this_.train_obj.duration) {
        return [...this_.train_obj.duration_select_option, {
          value: this_.train_obj.duration,
          label: `${this_.train_obj.duration}s`
        }];
      } else {
        return this_.train_obj.duration_select_option;
      }
    }
  },
  async mounted() {
    const calibrate_container_base_ref = this.$refs.calibrate_container_base_ref
    const container_w = calibrate_container_base_ref.clientWidth
    const container_h = calibrate_container_base_ref.clientHeight
    console.log('mounted container info:', container_w, container_h)
    // 初始化heatMap參數
    this.base_container_env.width = container_w
    this.base_container_env.height = container_h
    // 初始化media參數
    this.face_land_mark.media_config.width = container_w * 0.2
    this.face_land_mark.media_config.height = container_w * 0.2
    // 初始化Media
    await this.handleOpenCalibrateMedia()
    // 初始化FaceLandMarkDetect
    await this.handleInitFaceLandMark();
    // 初始化FaceMesh
    if (this.current_required_env_state.create_detector_completed && this.current_required_env_state.open_media_completed) {
      setInterval(() => {
        // 持續獲取視頻中的每一幀圖像來實時檢測人臉標記
        this.handleEstimateFaceMesh(this.$refs.calibrate_video_ref);
        this.face_land_mark.calibrate_before.systemFullScreenStatusDialogVisible = !screenFull.isFullscreen
      }, 100);
    }
    // 初始化模板
    this.handleListTrainTemplate()
    // 初始化參數
    this.handleInitCalibrationSettings()
    // 初始化熱力圖
    this.handleInitHeapMap()
    // 初始環境加載完畢
    this.current_required_env_state.init_app_env_completed = true
  },
  methods: {
    handleReCalibrateConfirm(val) {
      if (val === 'cancel') {
        this.face_land_mark.calibrate_before.reCalibrateConfirmDialogVisible = false
      } else if (val === 'confirm') {
        this.face_land_mark.calibrate_before.reCalibrateConfirmDialogVisible = false
        window.location.reload()
      }
    },
    async captureScreen() {
      try {
        const uploadParam = {}
        this.train_obj.heatmap_file_path = ''
        const canvas = await html2canvas(document.body, {useCORS: true})
        uploadParam.fileBase64 = canvas.toDataURL('image/jpeg')
        console.log(uploadParam.fileBase64)
        await uploadFile(uploadParam).then((response) => {
          if (response.code === 200) {
            this.train_obj.heatmap_file_path = response.data.filePath
          }
        });
      } catch (error) {
        console.error('Error capturing screen:', error);
      } finally {
        this.current_required_env_state.capture_screen_completed = true
      }
    },
    async handleFullscreen() {
      if (!screenFull.isEnabled) {
        return false
      }
      await screenFull.toggle()
      this.face_land_mark.calibrate_before.systemFullScreenStatusDialogVisible = false
      setTimeout(() => {
        const calibrate_container_base_ref = this.$refs.calibrate_container_base_ref
        const container_w = calibrate_container_base_ref.clientWidth
        const container_h = calibrate_container_base_ref.clientHeight
        console.log('reload container info:', container_w, container_h)
        // 初始化heatMap參數
        this.base_container_env.width = container_w
        this.base_container_env.height = container_h
        document.querySelector(".heatmap-canvas").remove()
        this.handleInitHeapMap()
      }, 50)
    },
    handleCloseCalibrateNoticeDialog() {
      this.handleNoticeAudio(false)
      this.face_land_mark.calibrate_before.firstCalibrateNoticeDialogVisible = false
      this.face_land_mark.calibrate_before.secondCalibrateNoticeDialogVisible = false
    },
    handleInitCalibrationSettings() {
      const detailParam = {}
      detailParam.settingId = 10000
      settingDetail(detailParam).then((response) => {
        if (response.code === 200) {
          this.settings.interpolate_value = response.data.interpolateValue
          this.settings.wait_time = response.data.waitTime
          this.settings.minimum_face_camera_distance_percent = response.data.minimumFaceCameraDistancePercent
          this.settings.maximum_face_camera_distance_percent = response.data.maximumFaceCameraDistancePercent
          this.settings.left_face_camera_distance_percent = response.data.leftFaceCameraDistancePercent
          this.settings.right_face_camera_distance_percent = response.data.rightFaceCameraDistancePercent
          this.settings.top_face_camera_distance_percent = response.data.topFaceCameraDistancePercent
          this.settings.bottom_face_camera_distance_percent = response.data.bottomFaceCameraDistancePercent
        }
      })
      console.log("settings", this.settings)
    },
    handleBackToTrainPage() {
      this.current_required_env_state.capture_screen_completed = false
      this.handleClearHeatMap()
      this.train_obj.train_result_id = ''
      this.train_obj.train_template_type = ''
      this.train_obj.train_template_id = ''
      this.train_obj.file_path = ''
      this.train_obj.result_data = []
      this.current_required_env_state.eye_tracking_completed = false
      this.heat_map.z_index = -999
      // 加載完成開始變換點位
      this.handleMovePredictTarget()
    },
    handleClearHeatMap() {
      const this_ = this
      let data = {
        max: 100,
        data: []
      }
      this_.heat_map.heatmap_instance.setData(data)
      this_.heat_map.predict_points = []
    },
    handleHeatMapView() {
      const this_ = this
      let data = {
        max: 100,
        data: this_.heat_map.predict_points
      }
      // 如果选择showHeatMap则展示
      if (this_.train_obj.show_heat_map === 1) {
        this_.heat_map.heatmap_instance.setData(data)
      }
    },
    handlePushDataToHeatMap(prediction) {
      const this_ = this
      let point = {
        x: Math.floor(prediction[0] * this.base_container_env.width),
        y: Math.floor(prediction[1] * this.base_container_env.height),
        value: 5
      }
      this_.heat_map.predict_points.push(point)
    },
    handleInitHeapMap() {
      const this_ = this
      this_.heat_map.heatmap_instance = heatmap.create({
        container: this.$refs.heatmapElement
      });
    },
    async moveTargetAndCollectTrainData() {
      const this_ = this
      let position_percent = this_.face_land_mark.calibrate_before.position_percent
      // 移動點位
      await move()

      // 計算兩點間的線性插值
      function interpolate(start, end, factor) {
        return start + (end - start) * factor;
      }

      // 設置 div 的位置
      function setPosition(pos) {
        this_.face_land_mark.calibrate_before.left = `${pos[0]}%`
        this_.face_land_mark.calibrate_before.top = `${pos[1]}%`
      }

      async function move() {
        for (let index = 0; index < position_percent.length; index++) {
          if (index === 0) {
            // 校準兩次
            for (let i = 0; i < 3; i++) {
              this_.face_land_mark.calibrate_before.current_second = 3 - i
              await sleep(1000)
            }
            this_.face_land_mark.calibrate_before.current_second = null
          }
          if (index === Math.ceil(position_percent.length / 2)) {
            this_.face_land_mark.calibrate_before.secondCalibrateNoticeDialogVisible = true
            // 播放提示音
            await this_.handleNoticeAudio(true, 2)
            // 第一次校準提示
            while (this_.face_land_mark.calibrate_before.secondCalibrateNoticeDialogVisible) {
              await sleep(50)
            }
            for (let i = 0; i < 3; i++) {
              this_.face_land_mark.calibrate_before.current_second = 3 - i
              await sleep(1000)
            }
            this_.face_land_mark.calibrate_before.current_second = null
          }
          let current_pos = position_percent[index];
          let next_pos = position_percent[(index + 1) % position_percent.length];
          for (let i = 0; i < this_.settings.interpolate_value - 1; i++) {
            while (this_.handleFaceState(this_.face_land_mark.face_mesh) === false) {
              this_.face_land_mark.calibrate_before.show_media = true
              // 狀態false持續等待
              await sleep(this_.settings.wait_time);
            }
            // 展示攝像頭畫面
            this_.face_land_mark.calibrate_before.show_media = false
            let interpolated_Pos = [
              interpolate(current_pos[0], next_pos[0], i / this_.settings.interpolate_value),
              interpolate(current_pos[1], next_pos[1], i / this_.settings.interpolate_value)
            ];
            // 變化點位
            setPosition(interpolated_Pos);
            // 每次插值後等待一段時間，否則可能無法看到 div 的移動
            await sleep(50);
            if (this_.handleFaceState(this_.face_land_mark.face_mesh)) {
              // 添加數據樣本
              this_.handleCalibrateData(this_.face_land_mark.face_mesh[0], this_.face_land_mark.calibrate_before.left,
                  this_.face_land_mark.calibrate_before.top);
            }
          }
        }
      }
    },
    handleDivState(div) {
      const this_ = this
      switch (div) {
          // 校準target圖像
        case 0:
          return this_.current_required_env_state.in_calibrate
          // 眼球追蹤target圖像
        case 1:
          return (this_.current_required_env_state.load_model_competed &&
              !this_.current_required_env_state.in_eye_tracking &&
              !this_.current_required_env_state.eye_tracking_completed &&
              !this_.current_required_env_state.in_move_target_to_ring)
          // 眼神追蹤測試頁面
        case 2:
          return (this_.current_required_env_state.in_eye_tracking || this_.current_required_env_state.eye_tracking_completed)
          // 模型生成加載提示頁面
        case 3:
          return this_.current_required_env_state.in_training
          // heatMap熱力圖頁面
        case 4:
          return this_.current_required_env_state.eye_tracking_completed
          // 眼球追蹤target圖像
        case 5 :
          return this_.current_required_env_state.in_eye_tracking
        case 6:
          // 圓環校準頁面
          return this_.current_required_env_state.in_move_target_to_ring
        default :
          return false
      }
    },
    handleMovePredictTarget() { // 移動google框架預測的眼球在畫面位置target圖像div
      const this_ = this
      console.log("showHeatMap", this_.train_obj.show_heat_map)
      this_.current_required_env_state.interval.movePredictTargetTimer = setInterval(() => {
        if (this_.handleFaceState(this_.face_land_mark.face_mesh)) {
          this_.getPrediction(this_.face_land_mark.face_mesh[0]).then((prediction) => {
            // 移動點位
            this_.face_land_mark.calibrate_after.target_div.left = Math.min(((prediction[0]) * 100), 100)
            this_.face_land_mark.calibrate_after.target_div.top = Math.min(((prediction[1]) * 100), 100)
          })
        }
      }, 25)
    },
    handleReCalibrate() {
      this.face_land_mark.calibrate_before.reCalibrateConfirmDialogVisible = true
    },
    handleStartEyeTrack() {
      const this_ = this
      if (!this_.train_obj.file_path || !this_.train_obj.train_template_id) {
        Message.error('請選擇模板')
        return
      }
      this_.handleSaveTrainResult()
      this_.current_required_env_state.in_eye_tracking = true
      // 終止點位移動
      clearInterval(this_.current_required_env_state.interval.movePredictTargetTimer)
      // 每50ms獲取一次樣本
      this_.current_required_env_state.interval.pushResultDataTimer = setInterval(() => {
        if (this_.handleFaceState(this_.face_land_mark.face_mesh)) {
          this_.getPrediction(this_.face_land_mark.face_mesh[0]).then((prediction) => {
            this_.train_obj.result_data.push([prediction.map(num => parseFloat(num.toFixed(2))), Date.now()])
            // push數據到熱力圖
            this_.handlePushDataToHeatMap(prediction)
            // 移動點位
            this_.face_land_mark.calibrate_after.target_div.left = Math.min(((prediction[0]) * 100), 100)
            this_.face_land_mark.calibrate_after.target_div.top = Math.min(((prediction[1]) * 100), 100)
          })
        }
      }, 50)
      setTimeout(async () => {
        clearInterval(this_.current_required_env_state.interval.pushResultDataTimer)
        // 結束測試
        this_.current_required_env_state.in_eye_tracking = false
        this_.current_required_env_state.eye_tracking_completed = true
        // 修改heatMap div
        this_.heat_map.z_index = 999
        // 展示heatMap
        this_.handleHeatMapView()
        this_.$nextTick(
            async () => {
              await this_.captureScreen()
              // 上傳結果
              this_.handleUpdateTrainResult()
            }
        )
      }, this_.train_obj.duration * 1000)
    },
    handleSelectTrainTemplate(template) {
      this.train_obj.file_path = this.handleTrainTemplateFilePath(template.filePath)
      this.train_obj.train_template_id = template.trainTemplateId
      this.train_obj.train_template_type = template.trainTemplateType
      this.train_obj.duration = template.trainTemplateDuration
      this.train_obj.show_heat_map = template.showHeatMap
    },
    handleTrainTemplateFilePath(filePath) {
      return process.env.VUE_APP_BASE_URL + filePath
    },
    chunkTrainTemplates(array, size) {
      return Array.from({length: Math.ceil(array.length / size)}, (v, i) =>
          array.slice(i * size, i * size + size)
      )
    },
    handleListTrainTemplate() {
      const listParam = {}
      listParam.pageNum = 1
      listParam.pageSize = 100
      listTrainTemplate(listParam).then((response) => {
        if (response.code === 200) {
          this.train_templates = response.data.rows
        }
      })
    },
    handleSaveTrainResult() {
      const saveParam = {}
      saveParam.trainTemplateId = this.train_obj.train_template_id
      saveParam.trainTemplateDuration = this.train_obj.duration
      saveTrainResult(saveParam).then((response) => {
        if (response.code === 200) {
          this.train_obj.train_result_id = response.data.trainResultId
        }
      })
    },
    handleUpdateTrainResult() {
      const updateParam = {}
      updateParam.trainResultData = JSON.stringify(this.train_obj.result_data)
      updateParam.trainResultId = this.train_obj.train_result_id
      updateParam.heatmapFilePath = this.train_obj.heatmap_file_path
      try {
        updateTrainResult(updateParam)
      } catch (error) {
        console.log('error:', error)
      } finally {
        this.current_required_env_state.capture_screen_completed = true
      }
    },
    async handleNoticeAudio(val, val1) {
      // 播放提示音
      if (val === true) {
        if (val1 === 1) {
          await this.$refs.audioRef1.play()
        } else if (val1 === 2) {
          await this.$refs.audioRef2.play()
        }
        // 等待提示音播放完畢
        // await new Promise(resolve => setTimeout(resolve, this.$refs.audioRef1.duration * 1000))
      } else if (val === false) {
        // 停止播放音頻
        await this.$refs.audioRef1.pause()
        this.$refs.audioRef1.currentTime = 0
        await this.$refs.audioRef2.pause()
        this.$refs.audioRef2.currentTime = 0
      }
    },
    async handleStartCalibrate() { // 開始校準
      const this_ = this
      this_.current_required_env_state.in_calibrate = !this_.current_required_env_state.in_calibrate;
      this_.current_required_env_state.show_app_index = false
      // 初始化target位置
      if (this_.current_required_env_state.in_calibrate === false) {
        return
      }
      this_.face_land_mark.calibrate_before.firstCalibrateNoticeDialogVisible = true
      // 播放提示音
      await this.handleNoticeAudio(true, 1)
      // 第一次校準提示
      while (this_.face_land_mark.calibrate_before.firstCalibrateNoticeDialogVisible) {
        await sleep(50)
      }
      // 移動校準點位,收集訓練模型數據
      await this_.moveTargetAndCollectTrainData()
      // 校準完成
      this_.current_required_env_state.in_calibrate = false;
      // 開始創建模型
      await this_.fitModule()
      // 校準完成,讓用戶將校準target圖像移入隨機位置圓環中
      this_.current_required_env_state.in_move_target_to_ring = true
      await this.handleMoveTargetToRing();
    },
    async handleMoveTargetToRing() {
      const this_ = this
      let stopMoveTargetToRingTimer;
      // 隨機設置圓環位置(從移動target點位的數組中隨機一個數組,防止跑出主容器)
      const ringTopLeftArray = this_.face_land_mark.calibrate_before.random_position_percent[Math.floor(Math.random() *
          this_.face_land_mark.calibrate_before.random_position_percent.length)
          ]
      console.log('ring div array', ringTopLeftArray)
      this_.face_land_mark.calibrate_after.ring_div.left = [ringTopLeftArray[0]]
      this_.face_land_mark.calibrate_after.ring_div.top = [ringTopLeftArray[1]]
      // 提示語
      this_.face_land_mark.calibrate_after.ring_div.notice = '請在10秒內將校準點移動到校準圓環內 | Please move the gaze point into the calibration ring within 10 seconds'
      // 獲取圓和圓環圓心,如果兩個圓心的X和Y差值絕對值，小於等於外圓環半徑,判定為符合要求
      // 圓環
      const ringX = this.base_container_env.width * this_.face_land_mark.calibrate_after.ring_div.left / 100
      const ringY = this.base_container_env.height * this_.face_land_mark.calibrate_after.ring_div.top / 100
      this_.current_required_env_state.interval.moveTargetToRingTimer = setInterval(() => {      // 圓
        const targetX = this.base_container_env.width * this_.face_land_mark.calibrate_after.target_div.left / 100
        const targetY = this.base_container_env.height * this_.face_land_mark.calibrate_after.target_div.top / 100
        // 圓環圓心(大小200px正方形計算)
        const ringPoint = [(ringX + 200) / 2, (ringY + 200) / 2]
        // 圓環圓心(大小50px正方形計算)
        const targetPoint = [(targetX + 50) / 2, (targetY + 50) / 2]
        // 計算兩圓心距離差值絕對值
        // 計算兩個圓心之間的距離
        const distanceOfTargetAndRing = Math.sqrt(
            Math.pow(targetPoint[0] - ringPoint[0], 2) +
            Math.pow(targetPoint[1] - ringPoint[1], 2)
        )
        console.log('circle centre point dist:', distanceOfTargetAndRing)
        if (distanceOfTargetAndRing + 25 <= 100) {
          this_.current_required_env_state.iz_move_target_to_ring = true
          stopMoveTargetToRingTimer()
          console.log('current alright')
        }
      }, 50)
      // 監聽十秒內用戶是否將校準target圖像移入圓環中
      await new Promise(resolve => {
        setTimeout(() => {
          console.log('normal term')
          resolve()
        }, 10 * 1000)
        stopMoveTargetToRingTimer = resolve
      })
      console.log('early term')
      clearInterval(this_.current_required_env_state.interval.moveTargetToRingTimer)
      // 成功開始測試
      if (this_.current_required_env_state.iz_move_target_to_ring) {
        this_.face_land_mark.calibrate_after.ring_div.notice = '校準全部完成，請繼續測試 | Calibration completed, the test program is about to begin ……'
        await sleep(2.5 * 1000)
        this_.current_required_env_state.in_move_target_to_ring = false
        console.log('alright')
      } else {
        // 失敗重新校準
        this_.face_land_mark.calibrate_after.ring_div.notice = '校準失敗，請重新校準 | Calibration failed, please recalibrate'
        await sleep(2.5 * 1000)
        console.log('wrong')
        window.location.reload()
      }
    },
    handleCalibrateData(faceMesh, targetLeft, targetTop) { // 處理校準數據
      tensorflow.tidy(() => {
        const image = this.handleFaceMeshDataArr(faceMesh)
        targetLeft = parseFloat(targetLeft.replace('%', '')) / 100
        targetTop = parseFloat(targetTop.replace('%', '')) / 100
        const mousePos = [targetLeft - 0.5, targetTop - 0.5]
        console.log('push train data')
        this.handleAddTrainData(image, mousePos)
      })
    },
    async handleInitFaceLandMark() {
      try {
        const faceLandmarksDetectModel = faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh
        const faceLandMarkDetectorConfig = {
          runtime: 'mediapipe',
          refineLandmarks: true,
          solutionPath: 'https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh'
        }
        this.face_land_mark_detect = await faceLandmarksDetection.createDetector(faceLandmarksDetectModel, faceLandMarkDetectorConfig)
        this.current_required_env_state.create_detector_completed = true
      } catch (error) {
        console.error('Error accessing handleInitFaceLandMark,', error)
      }
    },
    async handleOpenCalibrateMedia() {
      const properties = {
        audio: false,
        video: {
          width: this.face_land_mark.media_config.width,
          height: this.face_land_mark.media_config.height
        }
      };

      let mediaStream;
      // 確保能獲取到視頻流
      while (true) {
        try {
          mediaStream = await navigator.mediaDevices.getUserMedia(properties);
          break;
        } catch (error) {
          console.error('Error accessing camera:', error);
          alert("無法訪問攝像頭，請允許訪問並確保設備上的攝像頭可用。點擊確定重試。")
        }
      }

      return new Promise((resolve) => {
        const video = this.$refs.calibrate_video_ref;
        video.style.transform = 'scaleX(-1)';
        if ('srcObject' in video) {
          video.srcObject = mediaStream;
        }
        video.onloadedmetadata = () => {
          video.play();
          this.current_required_env_state.open_media_completed = true;
          resolve();
        }
      });
    },
    async handleEstimateFaceMesh(video) {

      try {
        if (this.$refs.calibrate_video_ref) {
          this.face_land_mark.face_mesh = await this.face_land_mark_detect.estimateFaces(video,
              {flipHorizontal: false})
          if (this.face_land_mark.face_mesh) {
            this.current_required_env_state.estimate_faces_completed = true
          }
          if (this.handleFaceState(this.face_land_mark.face_mesh) === false) {
            this.face_land_mark.calibrate_before.show_media = true
            await sleep(50)
          } else {
            this.face_land_mark.calibrate_before.show_media = false
          }
          // 繪製臉部點陣
          // const videoCanvas = this.$refs.calibrate_canvas_ref
          // this.handleDrawFace(this.face_land_mark.face_mesh, videoCanvas.getContext('2d'))
        }
      } catch (error) {
        console.error('Error accessing handleEstimateFaceMesh,', error)
      }
    },
    handleDrawFace(faces, ctx) {
      if (!this.current_required_env_state.estimate_faces_completed) {
        return
      }
      ctx.clearRect(0, 0, this.face_land_mark.media_config.width, this.face_land_mark.media_config.height)
      const left = [0, 0, 0]
      const right = [0, 0, 0]
      if (faces.length > 0) {
        for (const v of faces[0].keypoints) {
          v.x = this.face_land_mark.media_config.width - v.x
          if ('name' in v) {
            if (v.name === 'leftIris') {
              left[0] = left[0] + v.x
              left[1] = left[1] + v.y
              left[2] = left[2] + 1
            } else if (v.name === 'rightIris') {
              right[0] = right[0] + v.x
              right[1] = right[1] + v.y
              right[2] = right[2] + 1
            } else if (v.name === 'rightEye' || v.name === 'leftEye') {
              // 眼眶顏色
              this.handleDrawDot(ctx, v.x, v.y, 1, 'grey')
            }
          } else {
            // 點位顏色
            this.handleDrawDot(ctx, v.x, v.y, 1, '#6E4E9E')
          }
        }
        // 左眼球
        this.handleDrawDot(ctx, left[0] / left[2], left[1] / left[2], 5, 'black')
        // 右眼球
        this.handleDrawDot(ctx, right[0] / right[2], right[1] / right[2], 5, 'white')
        faces[0].box.xMin = this.face_land_mark.media_config.width - faces[0].box.xMax
        faces[0].box.xMax = faces[0].box.xMin + faces[0].box.width
        this.handleDrawRect(ctx, faces[0].box.xMin, faces[0].box.yMin, faces[0].box.width, faces[0].box.height)
      }
    },
    handleDrawDot(ctx, x, y, dotRadius, dotColor) {
      try {
        ctx.beginPath()
        ctx.arc(x, y, dotRadius, 0, 2 * Math.PI, false)
        ctx.fillStyle = dotColor
        ctx.fill()
      } catch (error) {
        console.error('drawDot exception:', error.message)
      }
    },
    handleDrawRect(ctx, x, y, width, height) {
      try {
        ctx.strokeStyle = '#6E4E9E'
        ctx.strokeRect(x, y, width, height)
      } catch (error) {
        console.error('drawRect exception:', error.message)
      }
    },
    handleFaceState(faceMesh) {
      if (faceMesh.length > 0) {
        const midX = (faceMesh[0].box.xMin + faceMesh[0].box.xMax) / 2
        const midY = (faceMesh[0].box.yMin + faceMesh[0].box.yMax) / 2
        const width = faceMesh[0].box.width
        if (width < this.face_land_mark.media_config.width * this.settings.minimum_face_camera_distance_percent) {
          this.face_land_mark.check_state_notice.color = 'red'
          this.face_land_mark.check_state_notice.message = '距離太遠 | You are too far away'
        } else if (width > this.face_land_mark.media_config.width * this.settings.maximum_face_camera_distance_percent) {
          this.face_land_mark.check_state_notice.color = 'red'
          this.face_land_mark.check_state_notice.message = '距離太近 | You are too close'
        } else if (midX > this.face_land_mark.media_config.width * this.settings.left_face_camera_distance_percent || (midX <
            this.face_land_mark.media_config.width * this.settings.right_face_camera_distance_percent)) {
          this.face_land_mark.check_state_notice.color = 'red'
          this.face_land_mark.check_state_notice.message = '請將臉部挪至畫面中央 | Please move your face to the center of the screen'
        } else if (midY > this.face_land_mark.media_config.height * this.settings.top_face_camera_distance_percent) {
          this.face_land_mark.check_state_notice.color = 'red'
          this.face_land_mark.check_state_notice.message = '請將頭部向上挪動 | Please move your head upward'
        } else if (midY < this.face_land_mark.media_config.height * this.settings.bottom_face_camera_distance_percent) {
          this.face_land_mark.check_state_notice.color = 'red'
          this.face_land_mark.check_state_notice.message = '請將頭部向下挪動 | Please move your head downward'
        } else {
          this.face_land_mark.check_state_notice.color = 'green'
          this.face_land_mark.check_state_notice.message = ''
          return true
        }
      } else {
        this.face_land_mark.check_state_notice.color = 'red'
        this.face_land_mark.check_state_notice.message = '沒有人臉 | No face is detected'
        return false
      }
      return false
    },
    whichDataset() {
      // Returns 'train' or 'val' depending on what makes sense / is random.
      if (this.face_land_mark.train_data_set.train.n === 0) {
        return 'train'
      }
      if (this.face_land_mark.train_data_set.val.n === 0) {
        return 'val'
      }
      return Math.random() < 0.2 ? 'val' : 'train'
    },
    handleAddTrainData(img, pos) {
      // Given an image, eye pos and target coordinates, adds them to our dataset
      pos = tensorflow.keep(
          tensorflow.tidy(() => {
            return tensorflow.tensor1d(pos).expandDims(0)
          })
      )
      const key = this.whichDataset()
      this.handleAddToTrainDataSet(img, pos, key)
    },
    handleAddToTrainDataSet(img, pos, key) {
      // Add the given x, y to either 'train' or 'val'.
      const trainDataSet = this.face_land_mark.train_data_set[key]
      if (trainDataSet.x == null) {
        trainDataSet.x = tensorflow.keep(img)
        trainDataSet.y = tensorflow.keep(pos)
      } else {
        const oldImage = trainDataSet.x
        trainDataSet.x = tensorflow.keep(oldImage.concat(img, 0))
        const oldY = trainDataSet.y
        trainDataSet.y = tensorflow.keep(oldY.concat(pos, 0))

        tensorflow.dispose([oldImage, oldY, pos])
      }
      trainDataSet.n += 1
    },
    handleFaceMeshDataArr(faceMesh) {
      const tensor2dValue = []
      this.handleFaceMeshEyeArrData(faceMesh, tensor2dValue)
      this.handleFaceMeshArr(faceMesh, tensor2dValue)
      return tensorflow.tidy(function () {
        return tensorflow.tensor2d(tensor2dValue).expandDims(0)
      })
    },
    handleFaceMeshArr(faceMesh, tensor2dValue) {
      for (const element of faceMesh.keypoints) {
        tensor2dValue.push([(element.x - faceMesh.box.xMin) / faceMesh.box.width - 0.5,
          (element.y - faceMesh.box.yMin) / faceMesh.box.height - 0.5,
          element.z / faceMesh.box.height])
      }
    },
    handleFaceMeshEyeArrData(faceMesh, dataArr) {
      const leftEyeRectArr = []
      leftEyeRectArr.push(...this.face_land_mark.eye_key_points.leftEye,
          ...this.face_land_mark.eye_key_points.leftIris,
          ...this.face_land_mark.eye_key_points.leftEyebrow)
      const leftEyeRect = this.handleFaceMeshEyeRectData(faceMesh, leftEyeRectArr)
      this.handleEyePartArrData(dataArr, faceMesh, this.face_land_mark.eye_key_points.leftIris, leftEyeRect)
      this.handleEyePartArrData(dataArr, faceMesh, this.face_land_mark.eye_key_points.leftIris, leftEyeRect)
      this.handleEyePartArrData(dataArr, faceMesh, this.face_land_mark.eye_key_points.leftIris, leftEyeRect)
      this.handleEyePartArrData(dataArr, faceMesh, this.face_land_mark.eye_key_points.leftIris, leftEyeRect)
      this.handleEyePartArrData(dataArr, faceMesh, this.face_land_mark.eye_key_points.leftEye, leftEyeRect)
      this.handleEyePartArrData(dataArr, faceMesh, this.face_land_mark.eye_key_points.leftEyebrow, leftEyeRect)
      const rightEyeRectArr = []
      rightEyeRectArr.push(...this.face_land_mark.eye_key_points.rightEye,
          ...this.face_land_mark.eye_key_points.rightIris,
          ...this.face_land_mark.eye_key_points.rightEyebrow)
      const rightEyeRect = this.handleFaceMeshEyeRectData(faceMesh, rightEyeRectArr)
      this.handleEyePartArrData(dataArr, faceMesh, this.face_land_mark.eye_key_points.rightIris, rightEyeRect)
      this.handleEyePartArrData(dataArr, faceMesh, this.face_land_mark.eye_key_points.rightIris, rightEyeRect)
      this.handleEyePartArrData(dataArr, faceMesh, this.face_land_mark.eye_key_points.rightIris, rightEyeRect)
      this.handleEyePartArrData(dataArr, faceMesh, this.face_land_mark.eye_key_points.rightIris, rightEyeRect)
      this.handleEyePartArrData(dataArr, faceMesh, this.face_land_mark.eye_key_points.rightEye, rightEyeRect)
      this.handleEyePartArrData(dataArr, faceMesh, this.face_land_mark.eye_key_points.rightEyebrow, rightEyeRect)
    },
    handleFaceMeshEyeRectData(faceMesh, eyeArr) {
      let minX = document.body.clientWidth
      let maxX = 0
      let minY = document.body.clientHeight
      let maxY = 0
      for (const element of eyeArr) {
        const value = faceMesh.keypoints[element]
        if (value.x < minX) {
          minX = value.x
        }
        if (value.x > maxX) {
          maxX = value.x
        }
        if (value.y < minY) {
          minY = value.y
        }
        if (value.y > maxY) {
          maxY = value.y
        }
      }
      return [minX, minY, maxX, maxY]
    },
    handleEyePartArrData(dataArr, faceMesh, eyePart, eyeRect) {
      const width = eyeRect[2] - eyeRect[0]
      const height = eyeRect[3] - eyeRect[1]
      for (const element of eyePart) {
        dataArr.push([(faceMesh.keypoints[element].x - eyeRect[0]) / width - 0.5,
          (faceMesh.keypoints[element].y - eyeRect[1]) / height - 0.5,
          faceMesh.keypoints[element].z / width])
      }
    },
    loadModel: async function () {
      this.current_required_env_state.in_training = true
      this.face_land_mark.training.current_model = await tensorflow.loadLayersModel(
          this.face_land_mark.training.best_module_path)
      this.current_required_env_state.in_training = false
      this.current_required_env_state.load_model_competed = true
    },
    async fitModule() {
      const this_ = this
      console.log('train dataset length,',
          this_.face_land_mark.train_data_set.val.n, this_.face_land_mark.train_data_set.train.n)
      // 設置模型訓練狀態處於訓練中
      this_.current_required_env_state.in_training = true
      const epochs = 100
      let batchSize = Math.floor(this_.face_land_mark.train_data_set.train.n * 0.1)
      batchSize = Math.max(2, Math.min(batchSize, 64))
      if (this_.face_land_mark.training.current_model === null) {
        // 當前模型為空 創建
        console.log('create model')
        this_.face_land_mark.training.current_model = this_.createModule(this_.face_land_mark.train_data_set.train.x.shape)
      }
      let bestEpoch = -1
      let bestTrainLoss = Number.MAX_SAFE_INTEGER
      let bestValLoss = Number.MAX_SAFE_INTEGER
      this_.face_land_mark.training.current_model.compile({
        optimizer: tensorflow.train.adam(0.0001),
        loss: 'meanSquaredError'
      })
      await this_.face_land_mark.training.current_model.fit(this_.face_land_mark.train_data_set.train.x, this_.face_land_mark.train_data_set.train.y, {
        batchSize: batchSize,
        epochs: epochs,
        shuffle: true,
        validationData: [this_.face_land_mark.train_data_set.val.x, this_.face_land_mark.train_data_set.val.y],
        callbacks: {
          onEpochEnd: async function (epoch, logs) {
            this_.face_land_mark.training.epochs_trained += 1
            if (logs.val_loss < bestValLoss) {
              // Save model
              bestEpoch = epoch
              bestTrainLoss = logs.loss
              bestValLoss = logs.val_loss
              // Store best model:
              await this_.face_land_mark.training.current_model.save(this_.face_land_mark.training.best_module_path)
            }
            return await tensorflow.nextFrame()
          },
          onTrainEnd: async function () {
            console.log('load model')
            // Load best model:
            this_.face_land_mark.training.epochs_trained -= epochs - bestEpoch
            await tensorflow.loadLayersModel(
                this_.face_land_mark.training.best_module_path).then((loadModel) => {
              this_.face_land_mark.training.current_model = loadModel
              this_.current_required_env_state.in_training = false
              this_.current_required_env_state.load_model_competed = true
              // 加載完成開始變換點位
              this_.handleMovePredictTarget()
            })
          }
        }
      })
    },
    createModule(inputShape) {
      const inputImage = tensorflow.input({
        name: 'image',
        shape: [inputShape[1], inputShape[2]]
      })
      const flat = tensorflow.layers.flatten().apply(inputImage)
      const dropout = tensorflow.layers.dropout(0.2).apply(flat)
      const dense = tensorflow.layers
          .dense({
            units: 100,
            activation: 'tanh',
            kernelInitializer: 'varianceScaling'
          }).apply(dropout)
      const output = tensorflow.layers
          .dense({
            units: 2,
            activation: 'tanh',
            kernelInitializer: 'varianceScaling'
          }).apply(dense)
      return tensorflow.model({
        inputs: inputImage,
        outputs: output
      })
    },
    getPrediction: async function (faceMesh) {
      // Return relative x, y where we expect the user to look right now.
      const eyes = this.handleFaceMeshDataArr(faceMesh)
      const prediction = this.face_land_mark.training.current_model.predict(eyes)
      const predictionData = await prediction.data()
      tensorflow.dispose([eyes, prediction])
      return [Math.max(0.05, Math.min(predictionData[0] + 0.5, 0.95)),
        Math.max(0.05, Math.min(predictionData[1] + 0.5, 0.95))]
    },
  }
}
</script>

<style scoped lang="scss">
.eye-track {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: 2;

  &::after {
    content: '';
    position: absolute;
    width: 5%;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: black;
  }

  &::before {
    content: '';
    position: absolute;
    width: 5%;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: black;
  }
}

.calibrate-media-base {
  z-index: 4
}

.calibrate-container-base {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .calibrate-notice-alert {
    position: absolute;
    top: 80%;
    left: 80%;
  }

  .calibrate-video {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: 10%;
    border-radius: 50%;
    overflow: hidden;
  }

  .calibrate-canvas {
    position: absolute;
    background-image: url("../../../assets/eye-track/calibrate-user.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    transform: scale(1.001);
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
  }

  .start-calibrate-base {
    position: absolute;
    left: 50%;
    top: 80%;

    .start-calibrate-button {
      width: 120px;
      line-height: 1.2;
      transform: translate(-50%, 0);
      background: #6E4E9E;
      border-radius: 15px;
      opacity: 1;
      border-color: transparent;
      font-size: 15px;
      font-weight: bold;
      color: #FFFFFF;
      text-align: center;
      display: block;
      margin: 0;
      padding: 8px 25px;
    }

  }

  .heat-map-env {
    position: absolute;
    background-size: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .heat-map-env canvas {
    position: absolute;
    background-size: cover;
    width: 100%;
    height: 100%;
  }

  .back-to-index-button {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 200px;
    font-size: 14px;
    line-height: 1.2;
    font-weight: bold;
    color: #FFFFFF;
    background: #67449A;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.16);
    border-color: transparent;
    border-radius: 15px;
    opacity: 1;
    top: 90%;
    left: 50%;
    text-align: center;
    display: block;
  }

}

.calibrate-notice-first {
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 55%;
  height: 32px;
  font-size: 22px;
  font-weight: bold;
  color: #6E4E9E;
}

.calibrate-notice-first-dialog, .calibrate-notice-second-dialog, .calibrate-system-status-dialog,
.re-calibrate-confirm-dialog {

  .calibrate-notice-dialog-title {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
  }

  .calibrate-notice-dialog-normal {
    word-break: break-word;
    font-size: 18px;
    padding: 5px 50px;
    margin: 10px 0
  }

  .calibrate-notice-dialog-padding {
    font-size: 18px;
    margin: 10px 0;
    padding: 5px 40px 5px 80px;
  }

  color: #6E4E9E;

  ::v-deep .el-dialog__body {
    color: #6E4E9E;
    padding-top: 0;
  }

  ::v-deep .el-dialog__footer {
    text-align: center;
  }

  ::v-deep .el-button--primary {
    background-color: #6E4E9E;
    border-color: #6E4E9E;
    border-radius: 15px;
    font-size: 15px;
  }

  ::v-deep .el-dialog {
    border-radius: 30px;
  }

  .calibrate-notice-dialog-button {
    width: 20%;
    line-height: 1.2;

    &.recalibrate {
      margin: 0 10px;
      width: 15%;
    }

    &.recalibrate-confirm {
      border-color: #6E4E9E;
      border-radius: 15px;
    }
  }
}

.calibrate-notice-load {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 100%;
  height: 32px;
  font-size: 24px;
  font-weight: bold;
  color: #6E4E9E;
  line-height: 32px;
  -webkit-background-clip: text;
  display: flex;
  align-items: center;
  justify-content: center;

  &.en {
    top: 53%;
  }
}

.user-face-state-notice {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 100%;
  height: 32px;
  font-size: 24px;
  font-weight: bold;
  color: #6E4E9E;
  line-height: 32px;
  -webkit-background-clip: text;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 4;
}

.calibrate-ring-image-notice {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 15%;
  left: 50%;
  width: 100%;
  height: 32px;
  font-size: 24px;
  font-weight: bold;
  color: #6E4E9E;
  line-height: 32px;
  -webkit-background-clip: text;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 4;
}

.current-calibrate-countdown-notice {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 40%;
  left: 50%;
  width: 100%;
  height: 32px;
  font-size: 80px;
  font-weight: bold;
  color: #6E4E9E;
  line-height: 32px;
  -webkit-background-clip: text;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calibrate-ring-image {
  position: absolute;
  transition: 50ms;
}

.calibrate-ring-image img {
  position: absolute;
  width: 200px;
  transform: translate(-50%, -50%);
}


.calibrate-target-image {
  position: absolute;
  transition: 50ms;
}

.calibrate-target-image img {
  position: absolute;
  width: 50px;
  transform: translate(-50%, -50%);
}

.eye-track-image {
  position: absolute;
  transition: 50ms;
  z-index: 3;
}

.eye-track-image img {
  position: absolute;
  width: 50px;
  transform: translate(-100%, -100%);
  right: calc(100% - 50px); /* 圖片寬度 */
  bottom: calc(100% - 50px); /* 圖片高度 */
}

.select-train-template {
  display: flex;
  flex-direction: column; /* 垂直flex*/
  position: absolute;
  left: 5%;
  top: 20%;
  width: 90%;

  .select-train-template-notice {
    font-size: 30px;
    font-weight: bold;
    padding: 50px;
    position: fixed;
    top: 2%;
    left: 50%;
    transform: translateX(-50%);
    color: #67449A;
    z-index: 3;

    &.en {
      top: 6%;
    }
  }

  .el-carousel {
    width: 100%;

    .el-carousel__item:nth-child(2n), .el-carousel__item:nth-child(2n+1) {
      background-color: #FFFFFF;
    }

    ::v-deep .el-carousel__indicator--horizontal {
      display: none;
      pointer-events: none;
    }

    .el-carousel-item {
      display: flex;
      justify-content: space-around;
    }

    .carousel-item-image {
      display: flex;
      width: 33.3%;
      flex-direction: column;
      align-items: center;

      .carousel-item-name {
        color: #67449A;
        font-size: 25px;
      }
    }

    ::v-deep .el-carousel__arrow--left {
      background-color: #6E4E9E;
    }

    ::v-deep .el-carousel__arrow--right {
      background-color: #6E4E9E;
    }

    .carousel-item-image.two-items {
      width: 50%; // 當只有兩個元素時，每個元素佔據50%的寬度
    }

    .carousel-item-image img, .carousel-item-image video {
      display: flex;
      width: 18.75vw; /* 360/1920 = 0.1875 */
      height: 45vh; /* 486/1080 = 0.45 */
      border-radius: 25px;
      border: 5px solid transparent;
    }

    .carousel-item-image video {
      background-color: rgb(148, 147, 147);
    }
  }

  .train-template-duration-and-start {
    display: flex;
    align-items: center;
    flex-direction: column;

    .start-train-button {
      position: relative; /* 修改為relative使得flexbox生效 */
      top: auto; /* 移除top值，因為它與flexbox不兼容 */
      left: auto;
      width: 150px;
      line-height: 14px;
      font-size: 14px;
      font-weight: bold;
      color: #FFFFFF;
      background: #67449A;
      box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.16);
      border-radius: 15px;
      border-color: transparent;
      opacity: 1;
      text-align: center;
      display: block;
    }

    .train-template-duration-start-calibrate {
      display: flex;
      margin-top: 40px
    }

    .train-template-duration-and-start-notice-select {
      margin-top: 80px;
      display: flex;
      align-items: center;

      .train-template-duration-and-start-notice-select-label {
        color: #6E4E9E;
        font-weight: bold;
        font-size: 18px
      }
    }

    .re-calibrate-button {
      position: relative; /* 修改為relative使得flexbox生效 */
      top: auto; /* 移除top值，因為它與flexbox不兼容 */
      left: auto;
      width: 150px;
      line-height: 14px;
      font-size: 14px;
      font-weight: bold;
      color: #6E4E9E;
      background: transparent;
      box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.16);
      border-radius: 15px;
      border-color: #6E4E9E;
      opacity: 1;
      text-align: center;
      display: block;
      margin-left: 20px;
    }

    .duration_select_option {
      position: relative; /* 修改為relative使得flexbox生效 */
      top: auto; /* 移除top值，因為它與flexbox不兼容 */
      left: auto;
      opacity: 1;
      width: 121px;
      height: 40px;
      margin-right: 5px;
    }
  }

  ::v-deep .duration_select_option .el-input__inner {
    border-color: #6E4E9E !important;
    outline: none;
    width: 121px;
    height: 40px;
    border-radius: 15px;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.16);
  }

}

@media (max-width: 1500px) {

  .start-train-button {
    width: 125px !important;
    height: 38px !important;
    line-height: 10px !important;
    font-size: 10px !important;
  }
  .re-calibrate-button {
    width: 125px !important;
    height: 38px !important;
    line-height: 10px !important;
    font-size: 10px !important;
  }


  .train-template-duration-and-start-notice-select {
    margin-top: 0 !important;

    .train-template-duration-and-start-notice-select-label {
      font-size: 10px !important;
    }

    ::v-deep .duration_select_option {
      width: 110px;
      height: 35px;

      .el-input__inner {
        width: 110px;
        height: 35px;
      }

      .el-input__suffix {
        right: 15px;
      }

      .el-select__caret {
        color: #6E4E9E;
      }
    }

  }

  .train-template-duration-start-calibrate {
    margin-top: 20px !important;
  }

  .select-train-template-notice {
    font-size: 10px !important;
  }

  .calibrate-notice-first {
    font-size: 16px;
  }

  .user-face-state-notice {
    font-size: 20px;
  }

  .current-calibrate-countdown-notice {
    font-size: 60px;
  }

  ::v-deep .el-dialog {
    width: 60% !important;
  }
  .calibrate-notice-first-dialog, .calibrate-notice-second-dialog, .calibrate-system-status-dialog,
  .re-calibrate-confirm-dialog {

    .calibrate-notice-dialog-title {
      font-size: 15px;
    }

    .calibrate-notice-dialog-normal {
      font-size: 12px;
      padding: 2px 35px;
      margin: 3px 0
    }

    .calibrate-notice-dialog-padding {
      font-size: 12px;
      padding: 2px 28px 5px 50px;
      margin: 3px 0
    }

    ::v-deep .el-button--primary {
      font-size: 12px;
    }

  }

  ::v-deep .el-carousel__container {
    height: 100% !important;
  }
  .el-carousel {
    height: 380px;

    .el-carousel-item {
      align-items: center;

      .carousel-item-name {
        font-size: 15px !important;
      }
    }

    .carousel-item-image img, .carousel-item-image video {
      width: 47vw; /* 360/768 = 0.47 */
      height: 30vh !important; /* 486/540 = 0.9 */

      &:nth-child(2) {
        width: 28% !important;
      }

    }
  }
}
</style>
