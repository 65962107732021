import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import './css/base.css'
import {Message, MessageBox} from "element-ui";

const _Message = Message
const _MessageBox = MessageBox

Vue.config.productionTip = false
Vue.prototype.$message=_Message
Vue.prototype.$msgbox=_MessageBox
Vue.prototype.$alert=_MessageBox.alert
Vue.prototype.$confirm=_MessageBox.confirm

new Vue({
  router, render: h => h(App)
}).$mount('#app')
Vue.config.errorHandler = function (err, vm, info) {
  console.log(`Error: ${err.toString()}\nComponent: ${vm}\nInfo: ${info}`);
};
