import fetch from '@/utils/request'

export function listSetting(data) {
  return fetch({
    url: '/api/settings/list', method: 'get', data
  })
}

export function settingDetail(data) {
  return fetch({
    url: '/api/settings/detail', method: 'get', data
  })
}

export function updateSetting(data) {
  return fetch({
    url: '/api/settings', method: 'put', data
  })
}

