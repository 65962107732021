import Vue from 'vue'
import VueRouter from 'vue-router'
import signInView from '../views/eye-track/sign-in/index'
import calibrateView from '@/views/eye-track/track/index.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/', redirect: '/sign-in'
}, {
    path: '/sign-in', name: 'sign-in', component: signInView
}, {
    path: '/index', name: 'calibrate', component: calibrateView
}]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    if (!to.matched.length) {
        next('/')
        window.location.reload()
    } else {
        next()
    }
})
export default router
