<template xmlns="http://www.w3.org/1999/html">
  <div class="sign-in-container-base">
    <div class="app-logo">
      <img src="../../../assets/eye-track/logo.png" alt="">
      <p class="app-description">眼神追蹤測試系統</p>
      <p class="app-description en">Eye-tracking Test System</p>
    </div>
    <div class="app-copyright">
      <p>慧能培訓協會有限公司</p>
    </div>
    <div class="app-copyright en">
      <p>Pro-Talent Tech Ltd</p>
    </div>
    <div class="sign-in-container">
      <el-form
          ref="sign_in_form_ref"
          :model="sign_in.sign_in_param"
          :rules="sign_in.sign_in_rules"
          class="sign_in_form"
          autocomplete="on"
          label-position="left"
      >
        <el-form-item prop="userName">
          <el-input class="sign_in_input"
                    ref="userName"
                    v-model="sign_in.sign_in_param.userName"
                    placeholder="賬號 Account"
                    name="userName"
                    type="text"
                    tabindex="1"
                    autocomplete="on"
          />
        </el-form-item>
        <el-form-item prop="passWord">
          <el-input class="sign_in_input"
                    ref="passWord"
                    v-model="sign_in.sign_in_param.passWord"
                    placeholder="密碼 Password"
                    name="passWord"
                    type="password"
                    tabindex="1"
                    autocomplete="on"
          />
        </el-form-item>
        <el-button type="primary" size="medium"
                   class="sign_in_button"
                   :loading="sign_in.loading"
                   @click="handleSignIn">登入 Login in
        </el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import {handleSignIn} from "@/api/signin";
import {setToken} from "@/utils/auth";
import screenfull from "screenfull";

export default {
  data() {
    return {
      sign_in: {
        loading: false,
        sign_in_param: {
          userName: '',
          passWord: ''
        },
        sign_in_rules: {
          userName: [{required: true, trigger: 'blur', message: '用戶名不能為空 The username cannot be empty'}],
          passWord: [
            {required: true, trigger: 'blur', message: '密碼不能為空 The password cannot be empty'}
          ]
        }
      }
    }
  },
  methods: {
    handleSignIn() {
      this.$refs.sign_in_form_ref.validate(async (valid) => {
            if (valid) {
              if (!screenfull.isFullscreen) {
                await screenfull.toggle()
              }
              this.sign_in.loading = true
              const signInParam = {}
              signInParam.userName = this.sign_in.sign_in_param.userName
              signInParam.passWord = this.sign_in.sign_in_param.passWord
              handleSignIn(signInParam).then((response) => {
                if (response.code === 200) {
                  setToken(response.data.token)
                  this.sign_in.loading = false
                  this.$router.push("/index")
                } else {
                  this.sign_in.loading = false
                }
              }).catch(() => {
                this.sign_in.loading = false
              })
            } else {
              return false
            }
          }
      )
    }
  }
}
</script>

<style scoped lang="scss">
.sign-in-container-base {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('../../../assets/eye-track/bg.jpg');
  background-size: cover;
  overflow: hidden;
}

.app-logo {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);

  & img {
    width: 134px;
    height: 138px;
  }

  .app-description {
    display: flex;
    color: #67449A;
    height: 20px;
    font-size: 15px;
    font-family: Segoe UI-Bold, Segoe UI, serif;
    font-weight: bold;
    letter-spacing: 6px;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: transparent;
    white-space: nowrap;
    margin: 7px;
  }
}

.app-copyright {
  position: absolute;
  display: flex;
  color: #313131;
  height: 20px;
  font-size: 15px;
  top: 90%;
  left: 50%;
  font-family: Segoe UI-Bold, Segoe UI, serif;
  font-weight: bold;
  letter-spacing: 6px;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: transparent;
  white-space: nowrap;
  transform: translate(-50%, -50%);

  &.en {
    top: 94%;
  }
}

.sign-in-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 39.5%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 366px;
  height: 245px;
  background: #FFFFFF;
  box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 25px 25px 25px 25px;
  opacity: 0.77;

  .sign_in_form {
    position: absolute;
    width: 74.3%;
    left: 12.8%;
    top: 17.6%;
  }

  ::v-deep .sign_in_input .el-input__inner {
    background: #FAFAFA;
    border-radius: 15px 15px 15px 15px;
    opacity: 1;
    height: 39px;

    &:focus {
      border-color: #6E4E9E;
    }
  }

  .sign_in_button {
    position: absolute;
    width: 100%;
    height: 39px;
    background: #6E4E9E;
    border-radius: 15px 15px 15px 15px;
    opacity: 1;
    border-color: transparent;
  }
}

@media (max-height: 665px) {
  .app-logo {
    top: 20%;

    & img {
      width: 100px;
      height: 110px;
    }
  }
  .sign-in-container {
    width: 326px;
    height: 215px;

    .sign_in_form {
      top: 12%;
    }
  }

}

</style>
